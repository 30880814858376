import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "animate.css/animate.css";

import "./App.css";
import Home from "./Pages/Home";

const App = () => {
	return (
		<Router>
			<Switch>
				<Route path="/">
					<Home />
				</Route>
			</Switch>
		</Router>
	);
};

export default App;
