import React from "react";
import Section from "../Section";
import { FaEnvelope, FaMapPin, FaLinkedin } from "react-icons/fa";

import "./styles.css";
import contact from "../../Images/contact.jpg";
import Boxed from "../Boxed";

const SIZE = 32;

const CONTACTS = [
	{
		icon: <FaEnvelope size={SIZE} />,
		link: "mailto:grace.barnard@mail.mcgill.ca",
		description: "grace.barnard@mail.mcgill.ca",
	},
	{
		icon: <FaMapPin size={SIZE} />,
		link: "",
		description: "Toronto & Montreal",
	},
	{
		icon: <FaLinkedin size={SIZE} />,
		link: "https://www.linkedin.com/in/grace-barnard",
		description: "Grace Barnard",
	},
];

const CONTACT = ({ contact: { icon, link, description } }) => {
	return (
		<a href={link} className={"Social"}>
			<Boxed padding={16} circular borderColor={" #dcc1b1"}>
				<div className={"Icon"} style={{ width: SIZE, height: SIZE }}>
					{icon}
				</div>
			</Boxed>

			<span style={{ marginLeft: 16, fontWeight: "bold", fontSize: 18 }}>
				{description}
			</span>
		</a>
	);
};

const Contact = () => {
	return (
		<Section title={"Contact"}>
			<div className={"ContactContainer"}>
				<div className={"ContactHalf"}>
					<span className={"InTouch"}>Let's Get In Touch!</span>
					<div className={"Contacts"}>
						{CONTACTS.map(
							// eslint-disable-next-line react/jsx-pascal-case
							(item) => (
								<CONTACT contact={item} />
							)
						)}
					</div>
				</div>
				<div className={"ContactHalf"} style={{ width: "50%" }}>
					<img
						src={contact}
						style={{ width: "100%", height: 400, objectFit: "cover" }}
						alt=""
					/>
				</div>
			</div>
		</Section>
	);
};

export default Contact;
