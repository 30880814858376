import React from "react";

import "./styles.css";
import Section from "../Section";
import portrait from "../../Images/portrait.png";

import {
	FaThinkPeaks,
	FaRegSmile,
	FaLightbulb,
	FaCarSide,
} from "react-icons/fa";
import { GiTacos, GiSittingDog } from "react-icons/gi";

const SIZE = 32;
const ICONS = [
	{
		color: "#CAD7E4",
		icon: <FaThinkPeaks size={SIZE} />,
		description: "Forward Thinkers",
	},
	{
		color: "#F0EBE1",
		icon: <FaRegSmile size={SIZE} />,
		description: "Nice People",
	},
	{
		color: "#FAE1E8",
		icon: <FaLightbulb size={SIZE} />,
		description: "Bright Ideas",
	},
	{
		color: "#F0EBE1",
		icon: <GiTacos size={SIZE} />,
		description: "Tacos",
	},
	{
		color: "#FAE1E8",
		icon: <GiSittingDog size={SIZE} />,
		description: "Dogs",
	},
	{
		color: "#CAD7E4",
		icon: <FaCarSide size={SIZE} />,
		description: "Road Trips",
	},
];

const Box = ({ icon: { color, icon, description } }) => {
	return (
		<div className="PersonalityTrait">
			<div
				style={{
					background: color,
					padding: "1rem",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					width: "100%",
					height: "100%",
				}}
			>
				{icon}
				<p
					style={{
						textAlign: "center",
						margin: 0,
						marginTop: "0.5vw",
					}}
				>
					{description}
				</p>
			</div>
		</div>
	);
};
const About = () => {
	return (
		<Section title={"About Me"}>
			<p>
				I’m Grace. I live in Montreal but I also spend time in my hometown of
				Toronto, Canada. Right now I am a full-time student at McGill
				University. While many of my peers follow a straight business path into
				marketing, I am a HUGE people person and my interest in learning why
				people act the way they do is reflected through my focus of study; I am
				a Psychology major with minors in Behavioural Science and Marketing. My
				goal is to get into the creative side of business at the intersection of
				technology and marketing.
			</p>

			<p>
				When I'm not hitting the books you’ll find me writing songs, playing my
				guitar or piano and belting out tunes. I've got a pop-folk style, and I
				write songs that tell a story. I love the energy of performing at open
				mics or pubs and dream that one day one of my songs will make it into a
				movie.
			</p>

			<div className={"AboutRow"}>
				<img
					src={portrait}
					className="Portrait"
					alt="Grace Barnard Portrait"
				></img>
				<p className="FunStuff">
					AND... when I’m not making music, you'll find me exploring nature and
					eating my way through Montreal. Oh la la...la poutine, les croissants!
					C'est magnifique! You can also find me trying to find life balance on
					my yoga mat.
				</p>
				<div className="PersonalityIcons">
					{ICONS.map((icon) => (
						<Box icon={icon} />
					))}
				</div>
			</div>
		</Section>
	);
};

export default About;
