import React, { useState, useEffect, useLayoutEffect } from "react";
import "./styles.css";
import { HashLink as Link } from "react-router-hash-link";
import profile1 from "../../Images/profile1.jpg";
import signature from "../../Images/signature.png";
import { FaBars } from "react-icons/fa";
import nav from "../../Images/nav.png";

function useWindowSize() {
	const [size, setSize] = useState([0, 0]);
	useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);
	return size;
}

const Intro = () => {
	const [menuShown, setMenuShown] = useState(true);
	const [width] = useWindowSize();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		setMenuShown(width > 750);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [width]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		if (document)
			document.body.style.position =
				width > 750 ? "" : menuShown ? "fixed" : "";
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menuShown]);

	const scrollFn = (el) => {
		setMenuShown(width > 750);
		document.body.style.position = "";
		el.scrollIntoView({ behavior: "smooth" });
	};

	return (
		<div className={"Intro"}>
			<div
				className="CurvyImage"
				style={{
					backgroundImage: `url(${nav})`,
					backgroundRepeat: "no-repeat",
					backgroundPosition: "bottom",
					width: "100%",
					height: 200,
					position: "absolute",
					zIndex: -999,
					top: 0,
					left: 0,
					backgroundSize: "cover",
				}}
			/>

			<div className={"Header"}>
				{/* here I added the nav as background */}
				{/* broke the othercss */}

				<p className={"Logo"}>Grace Barnard</p>

				<button
					className={"MenuButton"}
					onClick={() => setMenuShown(!menuShown)}
				>
					<FaBars />
				</button>

				<div className={"NavBar"} style={{ opacity: menuShown ? 1.0 : 0.0 }}>
					<Link smooth to={"/#about_me"} scroll={scrollFn}>
						About
					</Link>
					<Link smooth to={"/#experience"} scroll={scrollFn}>
						Experience
					</Link>
					<Link smooth to={"/#music"} scroll={scrollFn}>
						Music
					</Link>
					<Link smooth to={"/#contact"} scroll={scrollFn}>
						Contact
					</Link>
				</div>
			</div>

			<div className={"Landing"}>
				<div className={"Bio"}>
					<span>
						Fearless. <br />
						Creative. <br />
						Determined. <br />
					</span>

					<p>
						<strong>Hello World!</strong>
					</p>
					<p>
						I’m currently finding inspiration through new experiences, capturing
						the spark and working harder than I did yesterday. Doing all of this
						while surrounding myself with people who bring out the best in me.
					</p>
					<div className={"Signature"}>
						<img src={signature} alt="Signature" />
					</div>
				</div>
				<div className={"Image"}>
					<div className={"Background"}></div>
					<img src={profile1} alt="" />
				</div>
			</div>
		</div>
	);
};

export default Intro;
