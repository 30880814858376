import React from 'react';

import './styles.css';
import Intro from "../../Components/Intro";
import About from "../../Components/About";
import Experience from "../../Components/Experience";
import Music from "../../Components/Music";
import Contact from "../../Components/Contact";

const Home = () => {


    return (
        <div className="Home">
            <Intro />
            <About />
            <Experience />
            <Music />
            <Contact />
        </div>
    )


};

export default Home;