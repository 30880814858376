import React from "react";
import Section from "../Section";
import "./styles.css";

const IMAGES = [...Array(7).keys()].map((key) =>
	require(`../../Images/exp${key}.png`)
);

const Experience = () => {
	return (
		<Section title={"Experience"}>
			<div className="text">
				<p>
					I am an aspiring marketing guru with a strong work ethic and loads of
					untapped creative energy, eager to bring these to the next big
					marketing strategy!
				</p>
				<p>
					Recently, I completed an intensive Digital Marketing course that
					covered market analysis, channels & tracking, financial modelling, ad
					bidding and auction, branding and social media, and SEO. I developed a
					very cool campaign strategy and user experience for one of South
					Africa’s largest NGOs, Greenpop. I would love to tell you all about
					it! I have a keen interest in emerging media technologies, and
					recently I have immersed myself into the world of VR & AR in search of
					new marketing opportunities.{" "}
				</p>
				<p>
					Beyond academics, I have a passion for creating and sharing
					experiences that engage and bring people together. Whether it's
					working collaboratively on executive committees, event planning or
					participating in marketing case studies, I get a kick out of putting
					my ideas to work and watching them come to life. &nbsp;
				</p>
				<p>
					My passion for engaging people and sharing experiences also extends
					into my personal life as a published songwriter and performer. Working
					with industry professionals through the creative process from
					concept-to-launch in addition to my full-time studies has forged me
					into a competent project manager. Performing for live audiences has
					made me an excellent communicator and confident public speaker.{" "}
				</p>
				<p>
					Last summer I was lucky enough to work for a management consulting
					company as a marketing assistant. I worked on a campaign through rough
					design concepts and I was successful at increasing traffic through
					social media and digital ad channels.{" "}
				</p>
				<p>
					Back in the days before university, I spent summers in Muskoka and
					Toronto working as a summer camp counsellor where I developed and
					delivered daily programming. Turns out, I've always been about unique
					and fun experiences and creating hype!{" "}
				</p>
			</div>

			<div className="row">
				{IMAGES.map((img) => (
					<img src={img.default} alt="" />
				))}
			</div>
		</Section>
	);
};

export default Experience;
