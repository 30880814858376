import React from 'react';

import './styles.css';

const Boxed = ({ children, nopadding=false, padding, style, thick, noborder, circular, borderColor }) => {

    return (
        <div className={"Boxed"} style={{ ...style, padding: nopadding ? 0 : padding || '16px 32px' }}>
            <div className={"Foreground"} style={{ border: noborder ? 'none' : `4px solid ${borderColor || 'var(--text)'}`,      borderRadius: circular ? '50%' : 0 }} />
            <div className={"Content"}>
                { children }
            </div>
        </div>
    )

};

export default Boxed;