import React from "react";

import music1 from "../../Images/music1.jpg";
import music2 from "../../Images/music2.png";
import "./styles.css";
import Section from "../Section";
import Boxed from "../Boxed";

import ResponsiveEmbed from "react-responsive-embed";

import { FaInstagram, FaFacebook, FaSpotify, FaYoutube } from "react-icons/fa";

const SIZE = 40;

const SOCIALS = [
	{
		icon: <FaInstagram size={SIZE} />,
		link: "https://www.instagram.com/gracebarnardmusic/",
	},
	{
		icon: <FaFacebook size={SIZE} />,
		link: "https://www.facebook.com/gracebarnardmusic",
	},
	{
		icon: <FaSpotify size={SIZE} />,
		link: "https://open.spotify.com/artist/6mLmGLiMYfrNleQGXX6taG",
	},
	{
		icon: <FaYoutube size={SIZE} />,
		link: "https://www.youtube.com/channel/UCyA65HFz9GyG1WSz68SrPCg",
	},
];

const Social = ({ social: { icon, link } }) => {
	return (
		<a href={link} className={"Social"}>
			<Boxed padding={8} circular>
				<div className={"Icon"} style={{ width: SIZE, height: SIZE }}>
					{icon}
				</div>
			</Boxed>
		</a>
	);
};

const Music = () => {
	return (
		<Section title={"Music"}>
			<div className={"Bio"} style={{ marginBottom: 32 }}>
				<p>
					Grace Barnard is a singer/songwriter from Toronto, Canada and a
					student at McGill University in Montreal during the school year. She’s
					just beginning to launch her original soft pop/folk music into the
					world. Her distinctive voice effortlessly touches audiences through
					its sweet and emotional tone. Early days on stage found her swaying to
					the gospel music of church choirs, then performing at jazz piano
					recitals. Her passion for composition sparked when she picked up the
					guitar at the age of 12 and has been a regular performer at coffee
					houses, open mics, and outdoor festivals in Toronto. She received
					radio-play on CBC radio for her first released original and
					inspirational single “Polaris” when she was sixteen. Since then she
					has launched an EP of 4 original songs “Missing Out On Me”, and the
					pop single “The Life I Had Before”- a song with universal appeal as
					the world locked itself down in covid quarantine. Grace hopes to reach
					an audience that will be moved by her emotional ballads and will find
					inspiration through her vulnerable, and thoughtful lyrics.
				</p>
				<div className={"Image"}>
					<img src={music1} alt="Grace Barnard Music 1" />
				</div>
			</div>
			<div className={"Bio"}>
				<div className={"Socials"}>
					<Boxed
						nopadding
						noborder
						style={{ marginBottom: 32, marginTop: 32, width: "100%" }}
					>
						<ResponsiveEmbed
							src="https://open.spotify.com/embed/artist/6mLmGLiMYfrNleQGXX6taG"
							ratio="4:2"
							allowTransparency="true"
							allow="encrypted-media"
						></ResponsiveEmbed>
					</Boxed>
					{SOCIALS.map((item) => (
						<Social social={item} />
					))}
				</div>

				<div className={"Image"}>
					<img src={music2} alt="Grace Barnard Music 2" />
				</div>
			</div>
		</Section>
	);
};
export default Music;
