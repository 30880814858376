import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import './styles.css';

const Section = ({ title, children }) => {

    return (
        <ScrollAnimation animateIn="animate__fadeInUp" delay={100} duration={0.75} animateOnce>
            <div className={`Section ${title.replaceAll(" ", "")}`} id={ title.toLowerCase().replaceAll(" ", "_") }>
                <span className={"Title"}>
                    { title }
                </span>

                { children }
            </div>
        </ScrollAnimation>
    )

};

export default Section;